@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;1,400&display=swap");
.login.login-1 .login-aside .aside-img {
  min-height: 450px; }

.login.login-1 .login-signin,
.login.login-1 .login-signup,
.login.login-1 .login-forgot {
  display: none; }

.login.login-1.login-signin-on .login-signup {
  display: none; }

.login.login-1.login-signin-on .login-signin {
  display: block; }

.login.login-1.login-signin-on .login-forgot {
  display: none; }

.login.login-1.login-signup-on .login-signup {
  display: block; }

.login.login-1.login-signup-on .login-signin {
  display: none; }

.login.login-1.login-signup-on .login-forgot {
  display: none; }

.login.login-1.login-forgot-on .login-signup {
  display: none; }

.login.login-1.login-forgot-on .login-signin {
  display: none; }

.login.login-1.login-forgot-on .login-forgot {
  display: block; }

@media (min-width: 992px) {
  .login.login-1 .login-aside {
    width: 100%;
    max-width: 700px; }
  .login.login-1 .login-content {
    width: 100%;
    max-width: 500px; }
    .login.login-1 .login-content .login-form {
      width: 100%;
      max-width: 450px; } }

@media (min-width: 992px) and (max-width: 1399.98px) {
  .login.login-1 .login-aside {
    width: 100%;
    max-width: 450px; } }

@media (max-width: 991.98px) {
  .login.login-1 .login-content .login-form {
    width: 100%;
    max-width: 400px; } }

@media (max-width: 575.98px) {
  .login.login-1 .aside-img {
    min-height: 300px !important;
    background-size: 400px; }
  .login.login-1 .login-content .login-form {
    width: 100%;
    max-width: 100%; } }
